import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import {
  ControllerState,
  ApplicationStatus,
  CheckoutSlotProps,
} from './types/types';
import {
  AppData,
  ApplicationProps,
} from './core/hooks/useApplicationContext/useApplicationContext';
import { CheckoutService } from './services/CheckoutService';
import { calculateStoreCreditAmounts } from './utils/calcRedeemableStoreCredit';
import { hasGiftCardItem } from './utils/hasGiftCardItem';
import { riseWixPluginLoadingInitiatedSrc35Evid203 } from '@wix/bi-logger-rise-ai-data/v2';
import { shouldRenderWidget } from './utils/shouldRenderWidget';
import { shouldDisableWidget } from './utils/shouldDisableWidget';
import { sitePluginId, sitePluginName } from './consts';

const checkoutSlotProps: Partial<CheckoutSlotProps> = {};

const appState: Partial<ApplicationProps> = {
  status: ApplicationStatus.INITIALIZING,
};

const appData: Partial<AppData> = {};

const controllerState: Partial<ControllerState> = {};

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  const initialize = async () => {
    controllerState.checkoutService = new CheckoutService({
      flowAPI,
      refreshCheckoutCallback: checkoutSlotProps.refreshCheckoutCallback!,
    });
    controllerState.checkoutInfo =
      await controllerState.checkoutService.getCheckout({
        checkoutId: checkoutSlotProps.checkoutId!,
      });

    const checkoutHasGiftCardItem = hasGiftCardItem(
      controllerState.checkoutInfo?.checkout!,
    );

    if (checkoutHasGiftCardItem) {
      flowAPI.controllerConfig.setProps({
        shouldRenderWidget: false,
      });
      return;
    }

    controllerState.wallet =
      await controllerState.checkoutService.getRiseWallet(
        controllerState.checkoutInfo.checkout!.currency!,
      );
    const updateCheckout = async () => {
      flowAPI.controllerConfig.setProps({
        status: ApplicationStatus.UPDATING,
      });

      const updatedCheckout =
        await controllerState?.checkoutService?.updateCheckout({
          checkout: controllerState?.checkoutInfo?.checkout!,
          giftCardCode: controllerState?.wallet?.giftCardInfo?.code!,
        });
      if (!updatedCheckout?.id) {
        return;
      }

      const updatedStoreCredit = Math.max(
        parseFloat(controllerState.wallet?.giftCardInfo?.balance!) -
          parseFloat(updatedCheckout?.payNow?.total?.convertedAmount!),
        0,
      );

      const { redeemableStoreCredit, userTotalStoreCredit } =
        calculateStoreCreditAmounts({
          giftCard: updatedCheckout?.giftCard!,
          wallet: controllerState.wallet!,
          checkoutTotal: updatedCheckout?.totalAfterGiftCard!,
          currency: updatedCheckout?.currency!,
          flowAPI,
        });

      flowAPI.controllerConfig.setProps({
        appData,
        isWalletEmpty: updatedStoreCredit === 0,
        status: shouldDisableWidget({
          total: updatedCheckout?.totalAfterGiftCard?.convertedAmount!,
          giftCard: updatedCheckout?.giftCard!,
        }),
        redeemableStoreCredit,
        userTotalStoreCredit,
        userTotalStoreCreditNumber: updatedStoreCredit,
        giftCardError: undefined,
      });
    };

    const amount = controllerState.checkoutInfo?.checkout?.totalAfterGiftCard!;

    const { redeemableStoreCredit, userTotalStoreCredit } =
      calculateStoreCreditAmounts({
        giftCard: controllerState.checkoutInfo?.checkout?.giftCard!,
        wallet: controllerState.wallet,
        checkoutTotal: amount,
        currency: controllerState.checkoutInfo.checkout?.currency!,
        flowAPI,
      });

    const appProps: ApplicationProps = {
      status: shouldDisableWidget({
        total:
          controllerState.checkoutInfo?.checkout?.totalAfterGiftCard
            ?.convertedAmount!,
        giftCard: controllerState.checkoutInfo?.checkout?.giftCard!,
      }),
      appData: appData as AppData,
      redeemableStoreCredit,
      userTotalStoreCredit,
      updateCheckout,
      isWalletEmpty:
        parseFloat(controllerState.wallet.giftCardInfo?.balance!) === 0,
      userTotalStoreCreditNumber: parseFloat(
        controllerState.wallet.giftCardInfo?.balance!,
      ),
      shouldRenderWidget: shouldRenderWidget({
        checkout: controllerState.checkoutInfo.checkout!,
        wallet: controllerState.wallet,
      }),
    };
    flowAPI.controllerConfig.setProps({ ...appProps });
  };

  const isSlotInitialized = () =>
    checkoutSlotProps.checkoutId && checkoutSlotProps.refreshCheckoutCallback;

  const initializeCheckoutSlotProps = async (
    key: keyof CheckoutSlotProps,
    value: any,
  ) => {
    checkoutSlotProps[key] = value;

    if (isSlotInitialized()) {
      controllerState.checkoutSlotProps =
        checkoutSlotProps as CheckoutSlotProps;
      flowAPI.bi?.report(
        riseWixPluginLoadingInitiatedSrc35Evid203({
          pluginId: sitePluginId,
          appName: sitePluginName,
        }),
      );
      await initialize();
    }
  };

  return {
    async pageReady() {
      appData.appId = flowAPI.controllerConfig.appParams.appDefinitionId;
      appData.appName = flowAPI.controllerConfig.appParams.appName;
      flowAPI.controllerConfig.setProps({ ...appState, appData });
    },
    exports: () => {
      return {
        set checkoutId(checkoutId: string) {
          initializeCheckoutSlotProps('checkoutId', checkoutId);
        },
        onRefreshCheckout(callback: () => void) {
          initializeCheckoutSlotProps('refreshCheckoutCallback', callback);
        },
      };
    },
  };
};
export default createController;
